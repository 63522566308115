import * as React from "react";
import Layout from "../../components/layout";
import PoliciesContent from "../../components/about/policiesContent";
import BannerImage from "../../components/master/BannerImage";
import heroImage from "../../assets/about/policies-hero.jpg";

const PoliciesPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Polices" />
      <PoliciesContent />
    </Layout>
  );
};

export default PoliciesPage;
