import React from "react";
import childprotectionpolicy from "../../assets/about/Child_Protection_Policy.pdf";
import antibullyingpolicy from "../../assets/about/AntiBullying_Policy.pdf";
import complaintspolicy from "../../assets/about/Complaints_Handling_Policy.pdf";
import studentbehaviourpolicy from "../../assets/about/Student Behaviour Management Policy.pdf";
import uniformgroomingpolicy from "../../assets/about/Student Uniform and Grooming Policy.pdf";
import sunprotectionpolicy from "../../assets/about/Sun Protection Policy.pdf";
import parentalcodeofconductpolicy from "../../assets/about/Parental Code of Conduct.pdf";
import assessmentpolicyandprocedures from "../../assets/about/Assessment_Policy_and_Procedures_for_ROSA_final.pdf";
import assessmentpolicyandproceduresforrosa from "../../assets/about/Assessment_Policy_and_Procedures_for_ROSA_final.pdf";
import enrolmentpolicy from "../../assets/about/Enrolment_Policy.pdf";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import { about } from "../const";

const PoliciesContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Policies" />

        <p>
          St Maroun’s College has a number of policies to ensure all students
          are nurtured to grow into caring and responsible adults.
        </p>

        <p>
          The policies are administrative guidelines developed where necessary
          to provide procedures and regulations of the school and provide
          direction to students, staff and other members of the St Maroun’s
          community.
        </p>

        <p>Some of these policies can be viewed below:</p>

        <ul className="enrolmentContent">
          <li>
            <a href={childprotectionpolicy}>Child Protection Policy</a>
          </li>
          <li>
            <a href={antibullyingpolicy}>Anti-bullying Policy</a>
          </li>
          <li>
            <a href={complaintspolicy}>
              Complaints Policy
            </a>
          </li>
          <li>
            <a href={studentbehaviourpolicy}>
              Student Behaviour Management Policy
            </a>
          </li>
          <li>
            <a href={uniformgroomingpolicy}>
              Student Uniform and Grooming Policy
            </a>
          </li>
          <li>
            <a href={sunprotectionpolicy}>
              Sun Protection Policy
            </a>
          </li>
          <li>
            <a href={parentalcodeofconductpolicy}>
              Parental Code of Conduct
            </a>
          </li>
          <li>
            <a href={assessmentpolicyandproceduresforrosa}>
              Assessment Policy and Procedures for ROSA
            </a>
          </li>
          <li>
            <a href={assessmentpolicyandprocedures}>
              Assessment policy and procedures (11-12)
            </a>
          </li>
          <li>
            <a href={enrolmentpolicy}>
              Enrolment Policy
            </a>
          </li>
        </ul>
      </div>
      <SideBar items={about} title="About Us" />
    </ContentWrapper>
  </div>
);

export default PoliciesContent;
